import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import HeadingLevel from '~/mixins/HeadingLevel'

export default {
  mixins: [HeadingLevel],
  components: {
    AppImage,
    AppTitle,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    forceLinkReadMore: {
      type: Boolean,
      default: false,
    },
    imagePosition: {
      type: String,
      default() {
        return this.$themeSettings.components.ArticleBox.image.position
      },
    },
    lazyImage: {
      type: Boolean,
      default: false,
    },
  },
}
