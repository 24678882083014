import AppTitle from '@theme/components/atom/AppTitle'
import ArticleBox from '@theme/components/content/ArticleBox'
import ArticleTeaser from '@theme/components/content/ArticleTeaser'
import Container from '@theme/components/utils/Container'
import Divider from '@theme/components/molecule/Divider'
import HeadingLevel from '~/mixins/HeadingLevel'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'

export default {
  mixins: [HeadingLevel, UtilityGridMixin],
  components: {
    AppTitle,
    Container,
    Divider,
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
    articleItemComponent: {
      type: String,
      default() {
        return this.$themeSettings.components.FeaturedArticles.type
      },
    },
    fullViewportWidthWrapper: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.FeaturedArticles.fullViewportWidthWrapper
      },
    },
    headingClass: {
      type: String,
      default: 'Title--alpha',
    },
    headingText: {
      type: String,
      default() {
        return this.$t('FeaturedArticles.heading')
      },
    },
    perLine: {
      type: Object,
      default() {
        return this.$themeSettings.components.FeaturedArticles.perLine
      },
    },
    textBelowHeading: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columnGridClass: '',
      featuredArticleComponent: null,
    }
  },
  created() {
    this.columnGridClass = this.computeColumnClass(this.perLine)
    if (this.articleItemComponent === 'ArticleTeaser') {
      this.featuredArticleComponent = ArticleTeaser
    } else {
      this.featuredArticleComponent = ArticleBox
    }
  },
}
